import Script from 'next/script'

import '../styles/globals.scss'
import CookieConsent from 'react-cookie-consent'

function MyApp({ Component, pageProps }) {
  
  return (
    <>
          <Script
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                  __html: `
                  (function(w,d,s,l,i){
                    w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
                    var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;
                    j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_GTM}');
                  `,
              }}
          />
  
      <Script strategy="afterInteractive">
        {
          `
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:932009,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `
        }
      </Script>

      <link href="//fonts.cdnfonts.com/css/clash-display" rel="stylesheet"></link>

      <CookieConsent
        location="top"
        buttonText='ACCEPT'
        declineButtonText='CANCEL'
        cookieName='cookie'
        flipButtons={true}
        buttonClasses='button'
        declineButtonClasses='button'
        containerClasses="sticky bottom-0 flex align-center"
        style={{ background: '#313476', position: 'relative', alignItems: 'center' }}
        buttonStyle={{ backgroundColor: '#56cc9b', color: "#000", fontSize: "13px" }}
        declineButtonStyle={{ backgroundColor: '#888', color: "#FFFFFF", fontSize: "13px" }}
        expires={150}
        enableDeclineButton
      >
        <p><span className="font-semibold">Our website uses cookies.</span> A cookie is a small file of letters and numbers that we put on your device. These cookies allow us to distinguish you from other users and help us to provide you with a good experience and improve our site. <a href="https://concentrichx.com/privacy.php" target="_blank" className="font-semibold">Read our cookie policy</a> to learn more about the cookies we use. By continuing to use the Website you are agreeing to our use of cookies.</p>
      </CookieConsent>
      <Component {...pageProps} />
      
      
    </>
  )
}

export default MyApp
